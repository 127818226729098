import api from "@/modules/general/axiosInstance.js"

export default {


    //Consigue todos los ítems
    getItems() {
        return api.get("/traerItems")
    },

    //Consigue todos los métodos
    getMethods() {
        return api.get("/traerMetodos")
    },

    //Consigue todas las categorías
    getCategories() {
        return api.get("/traerCategorias")
    },

    //Consigue todas las configuraciones para este proyecto
    getConfigs() {
        return api.get("/getConfigs")
    },

    //Inserta los datos recogidos de otra parte
    insert(datos) {
        return api.post("/insertToBitacoraMoto", datos)
    },
    guardarDatosVehiculo(ot_id){
        return api.get("/guardarDatosMoto/"+ot_id)
    }
}