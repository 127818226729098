<template>
  <!-- El formulario no se renderizará hasta que todas las peticiones se hayan hecho correctamente -->
  <div v-if="!pageLoader">
    <v-container>
      <v-row class="justify-center text-center">
        <v-col cols="12" md="12" sm="12">
          <v-card>
            <v-card-title class="justify-center grey darken-3 white--text">
              Sensorial - Verificación
              <div style="text-align: center; display: block; width: 100%">
                Placa: {{ placa }}
              </div>
            </v-card-title>
          </v-card>

          <!-- Número de steps que aparecen en el formulario. El número varía según la cantidad de categorías
               para cada item -->
          <!-- <v-stepper v-model="e1">
            <v-stepper-header>
              <v-stepper-step
                v-for="(categoria, index) in categorias"
                :key="index + 1"
                :complete="e1 > index + 1"
                :step="index + 1"
                color="blue darken-4"
                style="padding: 12px"
              >
              </v-stepper-step>
            </v-stepper-header> -->

          <!-- <v-stepper-items> -->
          <!-- La cantidad de formularios varía según el número de categorías por item -->
          <v-col v-for="(categoria, index) in categorias" :key="index + 1">
            <h3
              class="font-weight-bold mb-6 text-decoration-underline"
              :id="categoria.id_ubicacion"
            >
              {{ categoria.categoria_item }}
            </h3>

            <div class="justify-end d-flex">
              <!-- Checkbox ubicado en la parte derecha al inicio del formulario, que permite seleccionar
                        todos los checkbox presentes en todo el formulario. Este checkbox es dependiente
                        de la config actual -->
              <v-checkbox
                label="Seleccionar todos"
                color="blue darken-4"
                v-model="selectAll"
                @change="changeSelectAll()"
                v-show="index == 0 && checkboxActivator == true"
              ></v-checkbox>
            </div>

            <!-- Itera sobre cada ítem presente en el array, y renderiza este bloque de código por cada
                   coincidencia encontrada -->
            <div v-for="(itemForm, index) in categoria.items" :key="index">
              <v-row class="d-flex justify-center">
                <!-- Nombre del item -->
                <div
                  class="caption font-weight-bold text-center justify-center mt-6"
                  style="width: 280px"
                  :id="itemForm.id_ubicacion"
                >
                  {{ itemForm.codigo + "." }} {{ itemForm.item }}
                </div>

                <!-- Data table en el cual se lista todos los métodos que pertenezcan a un item -->
                <v-col cols="12" class="mb-6">
                  <v-data-table
                    :headers="headers"
                    :items="itemForm.metodos"
                    class="text-center"
                    hide-default-footer
                    mobile-breakpoint="0"
                    elevation="0"
                  >
                    <template v-slot:item.metodo="props">
                      <v-col
                        :id="props.item.id_ubicacion"
                        :style="
                          props.item.frecuentemente_no_aplica
                            ? 'background-color:#FFFF44;'
                            : ''
                        "
                        >{{ props.item.metodo }}</v-col
                      >
                    </template>
                    <!-- eslint-disable-next-line -->
                    <template v-slot:item.revisado="props">
                      <v-col class="d-flex justify-center">
                        <!-- Checkbox que indica si cada método fue revisado o no -->
                        <v-checkbox
                          @change="cambioRevisado(props.item)"
                          v-model="props.item.revisado"
                          color="blue darken-4"
                        ></v-checkbox>
                      </v-col>
                    </template>
                    <template v-slot:item.no_aplica="props">
                      <div class="d-flex justify-center">
                        <!-- Checkbox que indica si cada método fue revisado o no -->
                        <v-checkbox
                          @change="cambioNoAplica(props.item)"
                          class="mr-4"
                          v-model="props.item.no_aplica"
                        ></v-checkbox>
                      </div>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </div>

            <v-col
              cols="12"
              class="justify-center align-center"
              v-show="index == categorias.length - 1"
            >
              <v-textarea
                rows="2"
                clearable
                filled
                auto-grow
                label="Comentarios del operador"
                id="textarea"
                v-model="comentariosOperario"
              ></v-textarea>
            </v-col>

            <!-- Botones para avanzar, retroceder o guardar los cambios efectuados en este formulario -->

            <!-- Botón que dirige al siguiente step, si el step en el que está es 1 -->
            <!-- <v-col
                  cols="12"
                  class="justify-center"
                  align="center"
                  v-show="index == 0"
                >
                  <v-btn color="blue darken-4" dark @click="scrollNextPage()">
                    <v-icon class="mr-1">mdi-chevron-right-circle</v-icon>
                    Siguiente
                  </v-btn>
                </v-col> -->

            <!-- Botón que dirige al siguiente step, si el step en el que está es mayor a 1 pero menor
                     al último step -->
            <!-- <v-col
                  cols="12"
                  class="justify-center"
                  align="center"
                  v-show="index != 0 && index != categorias.length - 1"
                >
                  <v-btn color="blue darken-4" dark @click="scrollNextPage()">
                    <v-icon class="mr-1">mdi-chevron-right-circle</v-icon>
                    Siguiente
                  </v-btn>
                </v-col> -->

            <!-- Botón que dirige al step anterior, si el step en el que está es mayor a 1 pero menor
                     al último step -->
            <!-- <v-col
                  cols="12"
                  class="justify-center"
                  align="center"
                  v-show="index != 0 && index != categorias.length - 1"
                >
                  <v-btn color="blue darken-4" dark @click="e1--">
                    <v-icon class="mr-1">mdi-chevron-left-circle</v-icon>
                    Atrás
                  </v-btn>
                </v-col> -->

            <!-- Botón para guardar el contenido del formulario, si el step en el que está es el último -->
           

            <!-- Botón que dirige al step anterior, si el step actual es el último -->
            <!-- <v-col
                  cols="12"
                  class="justify-center"
                  align="center"
                  v-show="index == categorias.length - 1"
                >
                  <v-btn color="blue darken-4" dark @click="e1--">
                    <v-icon class="mr-1">mdi-chevron-left-circle</v-icon>
                    Atrás
                  </v-btn>
                </v-col> -->
          </v-col>
          <!-- </v-stepper-items>
          </v-stepper> -->
          <v-col
            id="boton_finalizar"
              cols="12"
              class="justify-center mt-n5"
              align="center"
            >
              <v-btn
              
                color="green"
                dark
                @click="insertIntoBitacora()"
                :loading="loader"
              >
                <v-icon class="mr-1">mdi-content-save</v-icon>
                Guardar
              </v-btn>
            </v-col>
          <v-btn
            @click="modal_buscador_preguntas = true"
            color="primary "
            fab
            fixed
            dark
            bottom
            right
          >
            <v-icon>mdi-magnify</v-icon>
          </v-btn>

          <!-- <v-btn @click="$vuetify.goTo('#test')" color="primary " fab fixed  dark bottom right >
            <v-icon>mdi-magnify</v-icon>
          </v-btn> -->
        </v-col>
      </v-row>
      <v-col class="d-flex justify-center"
        ><v-btn fab color="primary" @click="volverHome">
          <v-icon> mdi-home </v-icon>
        </v-btn></v-col
      >

      <!-- Alerta que aparece una vez se guarden los cambios y el proceso sea exitoso -->
      <v-row class="justify-center d-flex text-center align-center">
        <v-col cols="12">
          <v-alert
            dense
            type="success"
            width="300px"
            style="
              position: fixed;
              left: 50%;
              bottom: 50px;
              transform: translate(-50%, 50%);
            "
            transition="scroll-y-reverse-transition"
            v-show="dataIsSaved == true"
          >
            <span class="font-weight-bold">¡Datos guardados!</span>
          </v-alert>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="modal_buscador_preguntas" width="500">
      <BuscadorPreguntas
        @cerrar-modal="modal_buscador_preguntas = false"
        @redirigir-buscador="redirigirBuscador"
        :categorias="categorias"
        v-if="modal_buscador_preguntas"
      />
    </v-dialog>
  </div>

  <!-- Icono de carga que aparece cuando no se han hecho todavía todas las peticiones para poder renderizar -->
  <div v-else class="d-flex justify-center align-center" style="height: 100vh">
    <v-progress-circular
      color="blue darken-4"
      :size="90"
      :width="9"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script>
// Importa varias peticiones de axios de este archivo
import formDataService from "@/modules/formulario/services/FormDataServiceMotos.js";
import BuscadorPreguntas from "./components/BuscadorPreguntas.vue";
export default {
  data() {
    return {
      modal_buscador_preguntas: false,

      ot_id: null,

      placa: null,

      runt: null,

      tipo_revision: null,

      tarifa: 0,
      valor_servicio: 0,
      iva: 0,

      //Número usado en el stepper, que cambia cada vez que se pasa de página.
      e1: 0,

      //Encabezados de cada data table
      headers: [
        {
          text: "Método",
          align: "start",
          sortable: false,
          filterable: false,
          value: "metodo",
        },
        {
          text: "Revisado/Aplica",
          sortable: false,
          filterable: false,
          align: "center",
          value: "revisado",
        },
        {
          text: "No Aplica",
          sortable: false,
          filterable: false,
          value: "no_aplica",
        },
      ],

      //Array de items que tiene valores provenientes de una base de datos
      items: [],

      //Array de métodos que tiene valores provenientes de una base de datos
      metodos: [],

      //Array de categorías que tiene valores provenientes de una base de datos
      categorias: [],

      //Array que contendrá los valores del formulario a enviar
      formulario: [],

      //Booleano que permite seleccionar todos los checkboxes de todos los ítems
      selectAll: false,

      //Booleano que activa un spinner
      loader: false,

      //Booleano que indicará si los datos fueron guardados, para que lance la alerta
      dataIsSaved: false,

      //Booleano que indicará si el checkbox de "Seleccionar todos" puede activarse, dependiendo del config
      checkboxActivator: false,

      //Booleano que se volverá falso una vez haya cargado el contenido de la página
      pageLoader: true,

      comentariosOperario: "",

      ultima_pagina_guardada: 1,
    };
  },
  components: {
    BuscadorPreguntas,
  },

  async created() {
    console.log(this.$route.query);
    this.ot_id = this.$route.query.ot_id;

    this.placa = this.$route.query.placa;

    this.runt =
      this.$route.query.runt == undefined ? null : this.$route.query.runt;

    this.tipo_revision = this.$route.query.tipo_revision;

    this.tarifa = parseInt(this.$route.query.tarifa);

    this.valor_servicio = parseInt(this.$route.query.valor_servicio);

    this.iva = parseInt(this.$route.query.iva);

    //Consigue todos los ítems mediante petición y los almacena en una variable
    formDataService.getItems().then((response) => {
      this.items = response.data;
    });

    //Consigue todas las categorías mediante petición y los almacena en una variable
    formDataService.getCategories().then((response) => {
      this.categorias = response.data;
    });
    let contador_categorias = 1;
    let contador_items = 1;
    let contador_metodos = 1;
    //Consigue todos los métodos mediante petición y aplica el siguiente filtro:
    await formDataService.getMethods().then((response) => {
      this.metodos = response.data;

      //Recorre todas las categorías y les asigna un array de ítems
      this.categorias.forEach((categoria) => {
        Object.assign(categoria, {
          id_ubicacion: "categoria_" + contador_categorias,
        });
        Object.assign(categoria, { items: [] });
        contador_categorias++;
        /* Recorre los items y asigna un array de métodos, si el id de categoría dentro del item 
           coincide con el id de categoría */
        this.items.forEach((item) => {
          if (item.categoria_item == categoria.id_categoria_item) {
            Object.assign(item, { id_ubicacion: "item_" + contador_items });
            Object.assign(item, { metodos: [] });
            contador_items++;
            /* Se recorren los métodos y si el id del ítem dentro del método coincide con el id del ítem */
            this.metodos.forEach((metodo) => {
              if (metodo.id_item == item.id_item) {
                Object.assign(metodo, {
                  id_ubicacion: "metodo_" + contador_metodos,
                });
                //Se asigna una propiedad a ese método, el cual permitirá saber si se revisó o no
                Object.assign(metodo, { revisado: false });

                Object.assign(metodo, { no_aplica: false });

                contador_metodos++;
                //Estos cambios se añaden al array de métodos que hay dentro del ítem
                item.metodos.push(metodo);
              }
            });

            // Al final de todo se añaden estos cambios al array de ítems que hay dentro de las categorías
            categoria.items.push(item);
          }
        });
      });

      // if (!this.validarFormulariosSensorialDiaActual(this.ot_id)) {
      //   this.$store.commit("agregarFormularioSensorial", {
      //     formulario: this.categorias,
      //     ot_id: this.ot_id,
      //   });
      // } else {
      //   this.cargarRespuestasGuardadas(this.ot_id);
      // }

      // Una vez se efectúen todas estas operaciones, al momento de conseguirlos e1 empieza en el paso 1
      this.$nextTick(() => {
        this.e1 = this.ultima_pagina_guardada;
      });
    });

    //Consigue todos las configs disponibles mediante request
    formDataService.getConfigs().then((response) => {
      //Si el config tiene algo que ver con este proyecto (incluye el prefijo "sensorial") y si el estado está activo
      if (
        parseInt(response.data.resultado_config[0].valor) == 1 &&
        response.data.resultado_conteo == 1
      ) {
        //El checkbox se vuelve activo
        this.checkboxActivator = true;
      } else {
        //De lo contrario, se desactiva
        this.checkboxActivator = false;
      }
    });

    //Una vez haga estas peticiones, muestra el formulario
    this.pageLoader = false;
  },

  watch: {
    /* Si la alerta toma un valor distinto, true o false, establece un timeout para que la alerta desaparezca */
    dataIsSaved(val) {
      if (val) {
        setTimeout(() => {
          this.dataIsSaved = false;
        }, 2500);
      }
    },
  },

  methods: {
    redirigirBuscador({ ubicacion }) {
      this.$vuetify.goTo("#" + ubicacion, { duration: 392, offset: 32 });
    },
    //Inserta todos los datos recogidos en el formulario mediante petición de axios
    async insertIntoBitacora() {
      if (this.validarTodosChequedos()) {
        this.$swal
          .fire({
            title: "Estas seguro guardar los datos?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: "Cancelar",
            confirmButtonText: "Confirmar",
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              this.loader = true;

              /* Crea un array de objetos en el cual se almacenarán los valores de los ítems y los métodos que hay
              dentro del ítem. Para ello, se itera sobre las categorías, los items y finalmente los métodos, los
              cuales se hacen cargo de insertar los items necesarios dentro del array. */
              this.categorias.forEach((categoria) => {
                categoria.items.forEach((item) => {
                  item.metodos.forEach((metodo) => {
                    this.formulario.push({
                      id_item: item.id_item,
                      id_metodo: metodo.id_metodo,
                      revisado: metodo.revisado,
                      no_aplica: metodo.no_aplica,
                      tecnico: "prueba tecnico",
                      comentarios: this.comentariosOperario,
                      ot_id: this.ot_id,
                      placa: this.placa,
                      runt: this.runt,
                      tipo_revision: this.tipo_revision,
                      tarifa:this.tarifa,
                      valor_servicio:this.valor_servicio,
                      iva:this.iva
                    });
                  });
                });
              });

              try {
                formDataService.guardarDatosVehiculo(this.ot_id);
              } catch (error) {
                console.log(error);
              }

              /* Luego, hace la petición a axios, y si guarda correctamente, vacía el array de formulario y lanza la
               alerta, sino lanza otra pero esta denota que algo salió mal */
              await formDataService
                .insert(this.formulario)
                .then(() => {})
                .catch((error) => {
                  console.log(error);
                  this.loader = false; // Desaparece el spinner
                  this.formulario = []; // Vacía el formulario
                });

              this.loader = false; // Desaparece el spinner

              this.dataIsSaved = true; // Variable que hace lanzar la alerta

              this.formulario = []; // Vacía el formulario de todos modos para que no haya problemas de duplicación

              this.$router.push({ path: "/sensorial-inspector" });
            }
          });
      } else {
        this.$swal.fire({
          title: "Debe revisar todas las casillas",
          icon: "info",
          allowEscapeKey: false,
          allowOutsideClick: false,
          allowEnterKey: false,
          position: "center",
          showConfirmButton: true,
          confirmButtonText: "Ok",
        });
      }
    },

    validarTodosChequedos() {
      let formulario_valido = true;
      this.categorias.forEach((categoria) => {
        categoria.items.forEach((item) => {
          item.metodos.forEach((metodo) => {
            if (!metodo.revisado && !metodo.no_aplica) {
              formulario_valido = false;
            }
          });
        });
      });

      return formulario_valido;
    },

    cambioRevisado(metodo_cambiado) {
      let nueva_lista = [];
      this.categorias.forEach((categoria) => {
        categoria.items.forEach((item) => {
          item.metodos.forEach((metodo) => {
            if (metodo.id_metodo == metodo_cambiado.id_metodo) {
              if (metodo.revisado) {
                metodo.no_aplica = false;
              }
            }
          });
        });
        nueva_lista.push(categoria);
      });
      this.categorias = nueva_lista;

      this.$store.commit("cambioFormularioSensorial", {
        formulario: this.categorias,
        ot_id: this.ot_id,
        ultima_pagina: this.e1,
      });
    },
    cambioNoAplica(metodo_cambiado) {
      let nueva_lista = [];
      this.categorias.forEach((categoria) => {
        categoria.items.forEach((item) => {
          item.metodos.forEach((metodo) => {
            if (metodo.id_metodo == metodo_cambiado.id_metodo) {
              if (metodo.no_aplica) {
                metodo.revisado = false;
              }
            }
          });
        });
        nueva_lista.push(categoria);
      });
      this.categorias = nueva_lista;

      this.$store.commit("cambioFormularioSensorial", {
        formulario: this.categorias,
        ot_id: this.ot_id,
        ultima_pagina: this.e1,
      });
    },

    volverHome() {
      this.$swal
        .fire({
          title: "Estas seguro volver a la pagina principal?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Confirmar",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.$router.push({ path: "/sensorial-inspector" });
          }
        });
    },

    validarFormulariosSensorialDiaActual(ot_id) {
      let encontrado = false;
      if (this.$store.state.formularios_sensorial.length > 0) {
        let dia_actual = new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10);

        this.$store.state.formularios_sensorial.forEach((formulario) => {
          if (formulario.fecha_creacion != dia_actual) {
            this.$store.commit("eliminarFormulario", formulario.ot_id);
          }
          if (formulario.ot_id == ot_id) {
            encontrado = true;
          }
        });
      }
      return encontrado;
    },

    cargarRespuestasGuardadas(ot_id) {
      if (this.$store.state.formularios_sensorial.length > 0) {
        this.$store.state.formularios_sensorial.forEach((formulario) => {
          if (formulario.ot_id == ot_id) {
            this.ultima_pagina_guardada = formulario.ultima_pagina;
            this.categorias.forEach((categoria) => {
              categoria.items.forEach((item) => {
                item.metodos.forEach((metodo) => {
                  formulario.formulario.forEach(
                    (formulario_guardado_categoria) => {
                      formulario_guardado_categoria.items.forEach(
                        (formulario_guardado_item) => {
                          formulario_guardado_item.metodos.forEach(
                            (formulario_guardado_metodo) => {
                              if (
                                formulario_guardado_metodo.id_metodo ==
                                metodo.id_metodo
                              ) {
                                metodo.revisado =
                                  formulario_guardado_metodo.revisado;

                                metodo.no_aplica =
                                  formulario_guardado_metodo.no_aplica;
                              }
                            }
                          );
                        }
                      );
                    }
                  );
                });
              });
            });
          }
        });
      }
    },

    //Cambia todos los checkboxes de la siguiente manera:
    changeSelectAll() {
      /* Si esta variable es verdadera, recorre todos los items y dentro de ello itera todos los métodos
         que hay dentro de ese ítem */
      if (this.selectAll == true) {
        this.categorias.forEach((categoria) => {
          categoria.items.forEach((item) => {
            item.metodos.forEach((metodo) => {
              // A cada método de cada item su propiedad revisado será verdadera
              metodo.revisado = true;

      
              //El stepper se desplaza hasta el último paso por cuestiones de comodidad
              this.e1 = this.categorias.length;
            });
          });
        });
        this.$vuetify.goTo("#boton_finalizar");
      } else {
        /* Si no, hace el mismo recorrido y asigna la propiedad revisado a false */
        this.categorias.forEach((categoria) => {
          categoria.items.forEach((item) => {
            item.metodos.forEach((metodo) => {
              // A cada método de cada item su propiedad revisado será verdadera
              metodo.revisado = false;


              //El stepper se desplaza hasta el último paso por cuestiones de comodidad
              this.e1 = this.categorias.length;
            });
          });
        });
      }

      this.$store.commit("cambioFormularioSensorial", {
        formulario: this.categorias,
        ot_id: this.ot_id,
        ultima_pagina: this.e1,
      });
    },

    //Aumenta el v-model del stepper en 1 y desplaza el scroller de la ventana hacia arriba del todo
    scrollNextPage() {
      this.e1++;
      window.scrollTo(0, 0);
    },
  },
};
</script>
<style scoped>
>>> .v-data-table__wrapper {
  background-color: rgba(236, 236, 236, 0.8) !important;
  border-radius: 10px !important;
}

>>> .tr {
  height: 100px !important;
  padding-bottom: 50px !important;
  padding-top: 50px !important;
}

>>> #textarea {
  width: 100%;
}
</style>
